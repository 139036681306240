import React, {useEffect, useState} from "react";
import {HelmetDatoCms} from 'gatsby-source-datocms';

import PageHeader from "../components/PageHeader/PageHeader";
import PageSection from "../components/PageSection";
import {swiperScrollOptions} from "./index";
import {Title, TagLine} from "../components/PageParts";
import {motion} from "framer-motion";
import {fadeInUp} from "../style/motion-variants";
import styled from "styled-components";
import {colors} from "../style/style";
import worldMap from "../static/images/world-map.svg"
import SwiperCore, {Mousewheel, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import {graphql, navigate} from "gatsby";
import ProductsMap from "../components/Map/ProductsMap";
import Layout from '../components/layout';

SwiperCore.use([Pagination, Mousewheel]);

const SwiperProducts = styled(Swiper)`
  .swiper-slide {
    transform: translate3d(0, 0, 0);
  }

  .swiper-pagination-bullets {
    display: flex;
    flex-flow: column;
    justify-content: center;
    top: 50%;
    right: 50vw;
    transform: translate(600px, -50%);
    height: calc(100vh - 130px);
    @media (max-width: 1200px) {
      right: 50px;
      transform: translate(0, -50%);
    }
    @media (max-width: 767px) {
      right: 50px;
    }
    @media (max-width: 700px) {
      display: none;
    }

    .swiper-pagination-bullet {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      width: auto;
      height: auto;
      padding: 10px 0;
      margin: 0;
      background: none;
      font-size: 12px;
      color: ${colors.light};
      opacity: 1;
      position: relative;
      outline: none;

      .title {
        color: ${colors.light};
        opacity: 0;
        margin: 0 5px 0 0;
      }

      .bullet {
        width: 15px;
        height: 2px;
        background: ${colors.light};
        opacity: 0.5;
        transition: all 0.2s ease;
      }

      &:hover {
        .title {
          opacity: 0.5;
        }
      }

      &-active {
        font-size: 16px;
        background: none;
        flex-flow: column wrap;
        align-items: flex-end;

        .title {
          font-size: 16px;
          opacity: 1;
          width: 100%;
          margin: 0 0 5px;
          text-align: right;
        }

        .bullet {
          width: 74px;
          height: 7px;
          background: ${colors.orange};
          opacity: 1;
        }

        &:hover {
          .title {
            opacity: 1;
          }
        }
      }
    }
  }
`
const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
  scrollbar-width: none;
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #2A2034 20%, #2A2034 90%, rgba(0, 0, 0, 0) 100%);

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .description {
    min-height: 100%;

    ul {
      margin-top: 40px;
      padding-left: 20px;
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      li {
        padding-right: 16px;
        margin-bottom: 20px;
      }
    }
    @media (max-width: 1200px) {
      ul {
        margin-top: 30px;
      }
    }
    @media (max-width: 767px) {
      ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        li {
          padding-right: 0;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    max-width: 600px;
  }
`

const ObjectWrapper = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

export const productsQuery = graphql`{
    allDatoCmsProduct(sort: {fields: position, order: ASC}) {
        nodes {
            id
            title
            longDescriptionNode {
                childrenMarkdownRemark {
                    html
                }
            }
            slug
            productid
            allStates
            global
            cover {
                url
            }
            states {
                state
            }
        }
    }
    seo: datoCmsSeo(slug: {eq: "product-details"}) {
        meta: seoMetaTags {
            tags
        }
    }
}`;

const ProductsDetailsPage = ({entry, data = {}}) => {
    const {
        allDatoCmsProduct: {nodes: products},
        seo
    } = data;
    const hash = entry?.state?.hash;

    const paginationTitles = products.map(({title}) => title);
    const paginationSlug = products.map(({slug}) => slug);
    const initialSlide = +products?.find(p => p.slug === hash)?.productid || 0;
    const [swiper, setSwiper] = useState(null)

    useEffect(() => {
        const bullets = document.querySelectorAll(".swiper-pagination-bullet")
        const onBulletClick = (index) => {
            navigate(`/product-details#${paginationSlug[index]}`)
        }
        if (bullets) {
            bullets.forEach((bullet, index) => {
                bullet.addEventListener("click", onBulletClick.bind(bullet, index))
            })
        }
        if (swiper) swiper.slideTo(initialSlide)
        return () => {
            bullets.forEach(b => b.removeEventListener("click", onBulletClick))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const slideToProduct = (index) => {
        swiper && swiper.slideTo(index)
    }

    return <Layout>
        <HelmetDatoCms seo={seo?.meta}/>
        <PageHeader slideToProduct={slideToProduct} color={colors.light}/>
        <SwiperProducts
            speed={500}
            mousewheel={swiperScrollOptions}
            onSwiper={(s) => setSwiper(s)}
            autoHeight={true}
            direction={"vertical"}
            spaceBetween={0}
            slidesPerView={1}
            initialSlide={initialSlide}
            style={{height: '100vh'}}
            shortSwipes={false}
            longSwipesMs={150}
            longSwipesRatio={0.2}
            pagination={{
                clickable: true,
                renderBullet: function (index, className) {
                    return `
                        <span class=${className}>
                            <span class="title">${paginationTitles[index]}</span>
                            <span class="bullet"></span>
                        </span>
                    `;
                },
            }}
        >
            <PageHeader color={colors.light}/>
            {
                products.map(({id, title, longDescriptionNode, cover, states, global, allStates}) =>
                    <SwiperSlide key={id}>
                        {({isActive}) => (
                            <PageSection align={"flex-start"} padding={"0 0 0 30px"} background={`linear-gradient(90deg,
                                              rgba(42, 32, 52, 0.8) 13.24%, rgba(42, 32, 52, 0) 100%),
                                              rgba(35, 5, 85, 0.3) url(${cover.url})`}>
                                {isActive &&
                                <Content exit="exit" variants={fadeInUp} initial="initial" animate="animate">
                                    <Swiper
                                        onSwiper={(s) => {
                                            s.mousewheel.disable();
                                            setTimeout(() => {
                                                if (s.mousewheel) s.mousewheel.enable()
                                            }, 1000)
                                        }}
                                        style={{height: "100vh", margin: 0, padding: "15vh 0"}}
                                        nested
                                        freeMode
                                        freeModeMomentumBounce
                                        freeModeMomentumBounceRatio={10}
                                        mousewheel
                                        freeModeMinimumVelocity={10}
                                        slidesPerView={'auto'}
                                        direction={"vertical"}
                                    >
                                        <SwiperSlide>
                                            <Title className="products" style={{maxWidth: "900px"}}>
                                                {title}
                                            </Title>
                                            <TagLine>
                                                {global ?
                                                    "Available globally"
                                                    : allStates ?
                                                    "Availability in all states"
                                                    : "Availability in the states"}
                                            </TagLine>
                                            {global ?
                                            <img src={worldMap} style={{marginTop: "40px", maxWidth: "100%",}} />
                                                : allStates 
                                                  ? "" 
                                                  : <ProductsMap states={states}/>
                                            }
                                            <TagLine
                                                style={{paddingBottom: "10vh"}}
                                                className={"description"}
                                                dangerouslySetInnerHTML={
                                                    {__html: longDescriptionNode.childrenMarkdownRemark[0].html}}
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </Content>}
                            </PageSection>
                        )}
                    </SwiperSlide>)
            }
        </SwiperProducts>
    </Layout>
}

export default ProductsDetailsPage;
